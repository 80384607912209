<template>
  <div>
    <nav-header :title="header_title"></nav-header>
    
    <div class="paper">
      <div class="pic">
        <img :src="banner" style="width:100%;height:100%;">
      </div>
    </div>
    <div style="font-weight: bold;padding: 10px 0;padding-left: 18px;background-color: #f4f4f4;">
      {{ title }}
    </div>

    <div class="signinPage">
      <div class="cell">
        <div class="l">姓名：</div>
        <div class="r">
          <input type="text" v-model="form.name">
          <div class="err" v-if="nameErr">请输入姓名</div>
        </div>
      </div>
      <div class="cell">
        <div class="l">身份证号：</div>
        <div class="r">
          <input type="text" v-model="form.idNum" maxlength="18">
          <div class="err" v-if="idErr">身份证格式有误</div>
        </div>
      </div>
      <div class="cell">
        <div class="l">学生证号：</div>
        <div class="r">
          <input type="text" v-model="form.stuNum" maxlength="20">
        </div>
      </div>
      <div class="cell">
        <div class="l">手机号码：</div>
        <div class="r">
          <input type="text" v-model="form.mobile" maxlength="11">
          <div class="err" v-if="mobileErr">手机号格式有误</div>
        </div>
      </div>
    </div>

    <div class="signSubmit" @click="choose">提交</div>
    <button @click="getCode">Get code</button>
  </div>
</template>
  
<script>
  import NavHeader from "../../components/nav-header";
  import axios from "axios";
  import wx from 'weixin-js-sdk'
  export default {
    components: {
      NavHeader,
    },
    data() {
      return {
        header_title: "活动报名2",
        form: {
          name: "",
          idNum: null,
          stuNum: null,
          mobile: null,
        },
        id: 0,
        idErr: false,
        mobileErr: false,
        nameErr: false,
        banner: {}
      }
    },
    async mounted() {
      // this.getCode()
      this.id = this.$route.query.id
      this.banner = this.$route.query.img
      this.title = this.$route.query.title
      let timestamp = new Date().getTime()
      let noncestr = '2352oiegwg3th2s352h35oi3h'
      const { data } = await axios.get('https://api.education.auxp.top/wechat/jsapiTicket')
      axios.get('https://api.education.auxp.top/wechat/jsapiTicket/signature',{
        params: {
          url: 'https://h5.education.auxp.top',
          timestamp: timestamp, // 必填，生成签名的时间戳
          noncestr: noncestr, // 必填，生成签名的随机串
        }
      }).then((res)=>{
        let signature = res.data.data
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: 'wxa8e0fa0ce507378f', // 必填，公众号的唯一标识
          timestamp: timestamp, // 必填，生成签名的时间戳
          nonceStr: noncestr, // 必填，生成签名的随机串
          signature: signature,// 必填，签名
          jsApiList: ["getLocation","chooseImage"] // 必填，需要使用的JS接口列表
        })
        wx.ready(()=>{
          console.log(' ready !')
        })
        
      }).catch((err)=>{
        console.log('1err', err)
      })
    },
    methods: {
      getCode() {
				let uri = encodeURIComponent("https://h5.education.auxp.top/#/")
				let appid = 'wxa8e0fa0ce507378f'
				// window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${uri}&response_type=code&scope=snsapi_base&state=123#wechat_redirect`
           window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${uri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
			},
      choose() {
        wx.chooseImage({
          count: 1, // 默认9
          sizeType: ['original', 'compressed'], // 可以指定是原图还是压缩图，默认二者都有
          sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
          success: function (res) {
          var localIds = res.localIds; // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
          }
        });
      },
      submit() {
        const {form} = this
        // const valid =  this.checkForm()
        const valid =  true
        if(valid) {
          let params = {
            activityId: this.id,
            userId: this.$store.state.user.id,
            realName: form.name,
            idCardNumber: form.idNum,
            studentIdNumber: form.stuNum,
            mobile: form.mobile
          }
          wx.miniProgram.navigateTo({
            url: 'pages/payment/payment', // 替换为目标页面的路径
            success(res) {
              // 跳转成功
            },
            fail(err) {
              console.error('跳转失败', err);
            }
          });
          return
          // this.$api.Activity.Register(params).then((res)=>{
          //   if(res.code === 0) {
          //     this.$message.success("报名成功");
          //     setTimeout(()=>{
          //       this.$router.back();
          //     },1000)
          //   }
          // })
        }
      },
      checkForm() {
        const regId = /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[X])$)$/;
        this.idErr = !regId.test(this.form.idNum)
        const regMob = /^1[3-9]\d{9}$/
        this.mobileErr = !regMob.test(this.form.mobile)
        if(!this.form.name) {
          this.nameErr = true
        } else {
          this.nameErr = false
        }
        
        if(!this.idErr && !this.mobileErr && this.form.name && this.form.stuNum) {
          return true
        } else {
          return false
        }
      }
    }
  }
</script>
  
<style>
  .signinPage {
    padding: 20px;
    display: flex;
    flex-direction: column;
    padding-top: 55px;
  }

  .cell {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .cell>.r>input {
    text-align: right;
  }

  .signSubmit {
    width: 90%;
    height: 40px;
    text-align: center;
    line-height: 40px;
    font-weight: bold;
    color: #FFF;
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #2e7bfd;
    border-radius: 8px;
  }

  .err {
    color: red;
    font-size: 10px;
  }
</style>